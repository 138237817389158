import config from '@/config/index';
import CookieUtils from '@/utils/cookie.utils';

let cmpListener = false;
let cmpQueuedEvents: [ITrackingEvent, boolean][] = [];

export interface ITrackingEvent {
  event?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: string;
  socialNetwork?: string;
  socialAction?: string;
  socialTarget?: string;
  loginType?: string;
  virtualPagePath?: string;
  hmid?: string | null;
  position?: string;
  visitorLoginState?: string | boolean;
  registerType?: string;
  registerTypeDetail?: string;
  planId?: string;
  ecommerce?: unknown;
  videoPlayerType?: 'inline' | 'overlay';
  revolverplay?: string;
  videoPublishingdate?: string;
  action?: string;
  payload?: unknown;
  bunteStudio?: string;
  userType?: string;
}

export interface ISnowplowTrackingEvent {
  eventName: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  pageElementId?: string;
  pageElementName: string;
  pageElementPosition?: string;
  pageElementType: string;
  pageElementCreative?: string;
  pageElementCampaignName?: string;
  pageElementCampaignId?: string;
  userType?: string;
  loginType?: string;
}

interface ISnowplowGalleryTrackingEvent {
  galleryName: string;
  galleryProducerName: string;
  galleryEventName: string;
  galleryImageName: string;
  galleryPublishDateUtc: string;
}

type ISnowplowVideoTrackingEvent = 'play' | 'ms5' | 'ms25' | 'ms50' | 'ms75' | 'ms100' | 'clickunmute';

export interface ISnowplowGalleryVideoContextPayload {
  videoXymaticId: string;
  videoNameVms: string;
  videoInteraction: string;
  videoStatus: string;
  videoPlayType: string;
  videoSound: boolean;
  videoPlayerVersion: string;
  videoPlayerType: string;
  videoPublishDate: string;
  videoUpdateDate: string;
  videoSalesforcePartnerId: string;
  videoCreatorJobId: string;
  videoAutoplaySettingPage: string;
  videoAbTestId: string;
}

const TrackingUtils = {
  setPending() {
    window.localStorage.setItem('user:pending', '1');
  },
  clearPending() {
    window.localStorage.removeItem('user:pending');
    window.localStorage.removeItem('user:flags:pending-model-last-shown-day');
    window.sessionStorage.removeItem('user:flags:ignore-pending-modal');
  },
  getPending() {
    return window.localStorage.getItem('user:pending') || undefined;
  },
  setUserId(userId: string) {
    window.localStorage.setItem('user:id', userId);
  },
  setUserEmail(email: string) {
    window.localStorage.setItem('user:email', email);
  },
  clearUserId() {
    window.localStorage.removeItem('user:id');
  },
  clearUserEmail() {
    window.localStorage.removeItem('user:email');
  },
  getUserId() {
    return window.localStorage.getItem('user:id') || undefined;
  },
  isLoggedIn() {
    return !!CookieUtils.get(config.BUNTE_TOKEN);
  },
  async trackSnowplowEvent(event: ISnowplowTrackingEvent) {
    if ('utag' in window) {
      // @ts-ignore
      window.utag.link({
        event_name: event.eventName,
        event_category: event.eventCategory,
        event_action: event.eventAction,
        event_label: event.eventLabel,
        pageElementName: event.pageElementName,
        pageElementId: event.pageElementId || 'n/a',
        pageElementPosition: event.pageElementPosition || 'n/a',
        pageElementType: event.pageElementType,
        pageElementCreative: event.pageElementCreative || 'n/a',
        pageElementCampaignName: event.pageElementCampaignName || 'n/a',
        pageElementCampaignId: event.pageElementCampaignId || 'n/a',
        usertype: event.userType || 'n/a',
        loginType: event.loginType || 'n/a',
      });
    }
  },
  momentsGalleryTrackImageSnowplowEvent(event: ISnowplowTrackingEvent, galleryEvent: ISnowplowGalleryTrackingEvent) {
    if ('utag' in window) {
      // @ts-ignore
      window.utag.link({
        event_name: event.eventName,
        event_category: event.eventCategory,
        event_action: event.eventAction,
        event_label: event.eventLabel,
        pageElementName: event.pageElementName,
        pageElementId: event.pageElementId || 'n/a',
        pageElementPosition: event.pageElementPosition || 'n/a',
        pageElementType: event.pageElementType,
        pageElementCreative: event.pageElementCreative || 'n/a',
        pageElementCampaignName: event.pageElementCampaignName || 'n/a',
        pageElementCampaignId: event.pageElementCampaignId || 'n/a',
        galleryName: galleryEvent.galleryName,
        galleryProducerName: galleryEvent.galleryProducerName,
        galleryEventName: galleryEvent.galleryEventName,
        galleryImageName: galleryEvent.galleryImageName || 'n/a',
        galleryPublishDateUtc: galleryEvent.galleryPublishDateUtc,
      });
    }
  },

  momentsGalleryVideoTrackSnowplowEvent(event: ISnowplowVideoTrackingEvent, videoContextPayload: ISnowplowGalleryVideoContextPayload) {
    if ('utag' in window) {
      // @ts-ignore
      window.utag.link({
        event_name: `xymatic.video.${event}`,
        ...videoContextPayload,
      });
    }
  },
  /**
   * Pushes an event to GTM datalayer with the given object.
   * @param event
   * @param addMetadata
   */
  trackEvent(event: ITrackingEvent, addMetadata = true) {
    // Push data to datalayer
    let data = {
      ...event,
    };
    if (addMetadata) {
      data = {
        ...data,
        hmid: this.getUserId(),
        visitorLoginState: this.getPending() !== undefined ? 'pending' : this.isLoggedIn().toString(),
      };
    }
    if (window.dataLayer && process.env.NODE_ENV === 'production') {
      window.dataLayer.push(data);
    } else {
      console.log('DataLayer would push: ', data);
    }
    if ('utag' in window && data.eventCategory === 'gallery.view') {
      // @ts-ignore
      window.utag.link({
        event_name: 'gallery.view',
        event_category: 'gallery',
        event_action: 'gallery.view',
        event_label: event.eventLabel,
      });
    }
  },
  trackCmpQueuedEvent(event: ITrackingEvent, addMetadata = true) {
    if (window.consent) {
      this.trackEvent(event, addMetadata);
    } else {
      cmpQueuedEvents.push([event, addMetadata]);
      if (!cmpListener) {
        const triggerQueuedEvents = (): void => {
          cmpQueuedEvents.forEach(([queuedEvent, metadata]) => this.trackEvent(queuedEvent, metadata));
          cmpQueuedEvents = [];

          if (cmpListener) {
            cmpListener = false;
            window.removeEventListener('CmpConsentUpdate', triggerQueuedEvents);
          }
        };
        cmpListener = true;
        window.addEventListener('CmpConsentUpdate', triggerQueuedEvents);
      }
    }
  },
  trackPageView(path: string) {
    this.trackEvent({
      event: 'virtualPageview',
      virtualPagePath: path,
    });
  },
  getArticleData() {
    const articleData = document.querySelectorAll('script[type="tracking/article-details"]')[0] as HTMLElement;
    return articleData ? `${articleData.dataset.articleId}: ${articleData.dataset.kicker} - ${articleData.dataset.title}` : undefined;
  },
};
export default TrackingUtils;
