import TrackingUtils from '@/utils/tracking/tracking.utils';
import lazyLoadResource from '@/utils/lazyLoad.utils';

const onShareClickHandler = async (event: Event) => {
  const target = event.currentTarget as HTMLAnchorElement;
  const shareType = target.getAttribute('data-share-type');
  const title = target.getAttribute('data-share-title') || 'n/a';
  const kicker = target.getAttribute('data-share-kicker') || 'n/a';
  const pageContentId = target.getAttribute('data-share-pagecontentid') || 'n/a';

  TrackingUtils.trackSnowplowEvent({
    eventName: 'pageElement.click',
    eventCategory: 'pageElement',
    eventAction: 'pageElement.clickInternal',
    eventLabel: `${kicker}-${title}`,
    pageElementId: pageContentId,
    pageElementName: 'share_click',
    pageElementType: 'article',
    pageElementPosition: shareType || 'n/a',
    pageElementCreative: 'share_icon',
  });

  switch (shareType) {
    case 'facebook':
      TrackingUtils.trackEvent({
        event: 'social',
        socialNetwork: 'facebook',
        socialAction: 'share',
        socialTarget: window.location.href,
      });
      break;
    case 'twitter':
      TrackingUtils.trackEvent({
        event: 'social',
        socialNetwork: 'twitter',
        socialAction: 'tweet',
        socialTarget: window.location.href,
      });
      break;
    case 'whatsApp':
      TrackingUtils.trackEvent({
        event: 'social',
        socialNetwork: 'whatsapp',
        socialAction: 'share',
        socialTarget: window.location.href,
      });
      break;
    case 'email':
      TrackingUtils.trackEvent({
        event: 'social',
        socialNetwork: 'mailto',
        socialAction: 'share',
        socialTarget: window.location.href,
      });
      break;
    case 'pinterest':
      if (window.PinUtils) {
        window.PinUtils.pinAny();
      } else {
        const additionalAttributes = {
          type: 'text/javascript',
          'data-pin-custom': 'true',
        };
        await lazyLoadResource('//assets.pinterest.com/js/pinit_main.js', additionalAttributes);
        window.PinUtils.pinAny();
      }
      TrackingUtils.trackEvent({
        event: 'social',
        socialNetwork: 'pinterest',
        socialAction: 'pin',
        socialTarget: window.location.href,
      });
      break;
    default:
      console.warn('Invalid share listener');
  }
};

export const initShareButtons = () => {
  const shareButtons = document.querySelector('#share-buttons');
  if (shareButtons) {
    for (const shareButton of shareButtons.children) {
      shareButton.addEventListener('click', onShareClickHandler);
    }
  }
};
