import { IUserResponse } from '@bunte/bunte-api';
import { globalStore } from '@/store/global.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import WebAppUtils from '@/utils/webApp.utils';
import { registerCustomer as registerCustomerApi } from '@/api/user.api';
import { getNewsletterToken } from '@/api/newsletter.api';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Auth0Client {
  isAuthenticated(): Promise<boolean>;
  handleRedirectCallback(): Promise<unknown>;
  getIdTokenClaims(): Promise<{ email: string; sub: string; [k: string]: string | number }>;
  getUser(): Promise<Auth0User>;
  getTokenSilently(): Promise<unknown>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Auth0User {
  email: string;
}

export const auth0UserStore = {
  client: null as Auth0Client | null,
  user: null as Auth0User | null,
  isLoading: false,
  _isAuthenticated: null as boolean | null,

  newsletterToken: null as string | null,
  newsletterTokenValidUntil: null as Date | null,
  async injectScript() {
    console.log('Auth0: /store inject script');
    const s = document.createElement('script');
    s.async = true;
    s.src = 'https://cdn.auth0.com/js/auth0-spa-js/1.20/auth0-spa-js.production.js';
    document.body.appendChild(s);
    return new Promise((resolve) => {
      s.onload = resolve;
    });
  },

  async getClient(waitForClient: boolean): Promise<Auth0Client> {
    if (this.client) {
      return this.client;
    } else if (this.isLoading) {
      if (waitForClient) {
        let loops = 100;
        this.client = await new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            loops--;
            if (this.client) {
              clearInterval(interval);
              resolve(this.client);
            } else if (loops < 0) {
              clearInterval(interval);
              reject('Auth0 client loading timeout');
            }
          }, 100);
        });
        return this.client!;
      } else {
        throw new Error('Auth0: client is loading');
      }
    }
    this.isLoading = true;
    try {
      await this.injectScript();
      this.client = await window.createAuth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        cacheLocation: 'localstorage',
        redirect_uri: window.location.href,
        authorizationParams: {
          redirect_uri: window.location.href,
        },
        useRefreshTokens: true,
      });
    } catch (e) {
      console.error('Auth0: error', e);
      throw new Error(`Auth0: client error ${e}`);
    } finally {
      this.isLoading = false;
    }
    console.log('Auth0: /store getClient,  client loaded:', this.client);

    if (this.client) {
      return this.client;
    } else {
      throw new Error('Auth0: client not loaded');
    }
  },

  async login(): Promise<void> {
    console.log('Auth0: login attempt ...');
    await this.getClient(true);
    this.redirectUrl('auth0LoginRedirect', true);
    TrackingUtils.trackSnowplowEvent({
      eventName: 'userLogin.click',
      eventCategory: 'userLogin',
      eventAction: 'userLogin.click',
      eventLabel: 'n/a',
      pageElementName: 'n/a',
      pageElementType: 'n/a',
    });
    //@ts-ignore
    await this.client.loginWithRedirect({ redirect_uri: window.location.origin });

    globalStore.toggleUserNav(false);
  },

  redirectUrl(name: string, save: boolean): string | null {
    if (save) {
      const isNewsletterPage = window.location.pathname === '/newsletter-verwalten.html' || window.location.pathname === '/settings.html';
      if (isNewsletterPage) {
        localStorage.setItem(name, window.location.origin);
        return window.location.origin;
      }
      localStorage.setItem(name, window.location.href);
      return window.location.href;
    } else {
      const url = localStorage.getItem(name);
      localStorage.removeItem(name);
      return url;
    }
  },

  async handleRedirect(): Promise<void> {
    this.client = await this.getClient(true);
    if (location.search.includes('state=') && (location.search.includes('code=') || location.search.includes('error='))) {
      await this.client!.handleRedirectCallback();
      const isAuth = await this.client.isAuthenticated();
      const redirect = this.redirectUrl('auth0LoginRedirect', false);
      if (!isAuth) {
        console.error('Auth0: /store handleRedirect, not authenticated, redirecting to:', redirect);
        window.location.replace(redirect || '/');
      }

      await this.registerCustomer();

      const idToken = await this.client?.getIdTokenClaims();
      const idProvider = idToken?.sub?.split('|')[0];

      await TrackingUtils.trackSnowplowEvent({
        eventName: 'userLogin.complete',
        eventCategory: 'userLogin',
        eventAction: 'userLogin.complete',
        eventLabel: 'n/a',
        userType: 'standard', //If we create a new type of users (eg. premium, influencer, etc.) we should conditionally set this value
        loginType: idProvider || 'auth0',
        pageElementName: 'n/a',
        pageElementType: 'n/a',
      });
      if (redirect) window.location.replace(redirect);
    }
  },

  async isAuthenticated(): Promise<boolean> {
    this._isAuthenticated = (await this.client?.isAuthenticated()) ?? false;
    return this._isAuthenticated;
  },

  trackLoggedinStatus(): void {
    TrackingUtils.trackSnowplowEvent({
      eventName: 'userLoginStatus.loggedin',
      eventCategory: 'userLoginStatus',
      eventAction: 'userLoginStatus.loggedin',
      eventLabel: 'n/a',
      pageElementName: 'n/a',
      pageElementType: 'n/a',
    });
  },

  /**
   * Registers a customer in our DB using Auth0 authentication.
   *
   * This function performs the following steps:
   * 1. Ensures the Auth0 client is initialized.
   * 2. Checks if the client is authenticated.
   * 3. If authenticated, retrieves the ID token claims.
   * 4. Attempts to register the customer using the retrieved ID token.
   * 5. Logs any errors encountered during the registration process.
   *
   * @returns {Promise<void>} A promise that resolves when the registration process is complete.
   */
  async registerCustomer(): Promise<string | Error> {
    await this.getClient(true);

    if (await this.client!.isAuthenticated()) {
      const idToken = await this.client?.getIdTokenClaims();
      if (idToken) {
        try {
          return await registerCustomerApi(idToken);
        } catch (e: unknown) {
          if (e instanceof Error) {
            const promise = Promise.reject(e as Error);
            return promise;
          }
        }
      }
      const promise = Promise.reject(new Error('Auth0: /store registerCustomer() no id token claims'));
      return promise;
    }
    const promise = Promise.reject(new Error("Auth0: /store registerCustomer() Client isn't authenticated"));
    return promise;
  },

  async getUser(): Promise<Auth0User | null> {
    if (this.user) {
      return this.user;
    }
    await this.getClient(true);
    this.user = (await this.client?.getUser()) ?? null;
    return this.user;
  },

  async logout(): Promise<void> {
    await this.getClient(true);
    localStorage.removeItem('auth0_token');
    // @ts-ignore
    this.client?.logout({ returnTo: window.location.origin });

    TrackingUtils.clearUserId();
    TrackingUtils.trackEvent({
      event: 'logout',
      eventCategory: 'logout',
      eventAction: 'logout',
      eventLabel: undefined,
      loginType: undefined,
    });
    WebAppUtils.sendToWebapp({ reload: true });
  },

  async fetchUserNewsletterCreds(): Promise<{ token: string; email: string } | { error: string }> {
    this.client = await this.getClient(true);
    const user = await this.client?.getUser();
    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated || !user || !user.email) {
      return { error: 'User not authenticated or email not found' };
    }
    if (this.newsletterToken && this.newsletterTokenValidUntil && this.newsletterTokenValidUntil > new Date()) {
      return { token: this.newsletterToken, email: user.email };
    }
    const { token, validUntil } = await getNewsletterToken({ email: user.email });
    if (!token || !validUntil) {
      return { error: 'token or validUntil invalid' };
    }
    this.newsletterToken = token;
    this.newsletterTokenValidUntil = new Date(validUntil);
    return { token: this.newsletterToken, email: user.email };
  },
};

// TODO(mkorn): this needs to be removed, old, deprecated
export const userStore = {
  loginVisible: false,
  loginActiveComponent: 'login',
  loggedIn: false,
  loginEmail: '',
  isPaid: false,
  user: null as IUserResponse | null,
  userResolved: false,
  isMomentsProducer: false,
  loginInitialError: null as string | null,
  loginResultCallback: null as (() => void) | null,
  auth0Client: null,

  async login(): Promise<void> {
    this.redirectUrl('auth0LoginRedirect', true);
    //@ts-ignore
    await this.auth0Client?.loginWithRedirect({ redirect_uri: window.location.origin });
    TrackingUtils.trackEvent({
      event: 'login',
      eventCategory: 'login',
      eventAction: 'login',
      eventLabel: undefined,
      loginType: 'email',
    });
    globalStore.toggleUserNav(false);
  },
  async logout(redirectTo?: string): Promise<void> {
    console.log('Auth0: logout atempt ...', this.auth0Client, redirectTo);

    localStorage.removeItem('auth0_token');
    this.redirectUrl('auth0LogoutRedirect', true);
    // @ts-ignore
    this.auth0Client?.logout({ returnTo: window.location.origin });

    TrackingUtils.clearUserId();
    TrackingUtils.trackEvent({
      event: 'logout',
      eventCategory: 'logout',
      eventAction: 'logout',
      eventLabel: undefined,
      loginType: undefined,
    });
    WebAppUtils.sendToWebapp({ reload: true });
  },

  checkLoginStatus(): boolean {
    if (this.loggedIn) {
      // @ts-ignore
      this.auth0Client?.getTokenSilently({ ignoreCache: true, cacheMode: 'off' }).catch((e) => {
        this.loggedIn = false;
        this.logout();
      });
    }
    return this.loggedIn;
  },

  redirectUrl(name: string, save: boolean) {
    if (save) {
      localStorage.setItem(name, window.location.href);
      return window.location.href;
    } else {
      const url = localStorage.getItem(name);
      localStorage.removeItem(name);
      return url;
    }
  },
};
