import { IUserExistsResponse, IUserResponse } from '@bunte/bunte-api';

import axiosClient from '@/api/axios.client';
import { AxiosResponse } from 'axios';

interface IIdToken {
  email: string;
  sub: string;
  [k: string]: string | number;
}

export const login = (username: string, password: string): Promise<AxiosResponse> =>
  axiosClient
    .post('/user/login', {
      username,
      password,
    })
    .then((response) => response.data);

export const getUser = (): Promise<IUserResponse> => axiosClient.get('/user').then((response) => response.data);
export const userExists = (email: string): Promise<{ exists: boolean }> =>
  axiosClient
    .get('/user/exists', {
      params: { email },
    })
    .then((response) => response.data);

export const checkExistingEmail = async (email: string): Promise<IUserExistsResponse> => {
  const { data } = await axiosClient.get('/user/exists', {
    params: { email },
  });
  return data;
};

/**
 * Calls an API endpoint that registers a customer using the provided ID token.
 *
 * We're allowing an open format for the ID token, but we're only using the `email` and `sub` properties all of the time,
 * given_name and family_name are optional, they might not always be present in ID token.
 * If you need more properties you can add them to the `reqData` object.
 *
 * @param {Object} idToken - The ID token containing the customer's email, sub, and optional additional properties.
 * @param {string} idToken.email - The customer's email.
 * @param {string} idToken.sub - The customer's sub.
 * @param {Object.<string, string | number>} idToken.[k] - Additional properties of the ID token. Optional.
 * @return {Promise<string | Error>} A promise that resolves to the status of the registration ('exists' or 'created') or an error.
 */

export const registerCustomer = async (idToken: IIdToken): Promise<string | Error> => {
  const reqData = {
    email: idToken.email,
    auth0Id: idToken.sub,
    given_name: idToken.given_name,
    family_name: idToken.family_name,
  };
  const res = await axiosClient.post('/api/v1/auth/user', reqData);
  switch (res.data.code) {
    case 201:
      console.log('Auth0: customer created');
      return res.data.message;
    case 200:
      if (res.data.message === 'Customer already exists') {
        console.log('Auth0: customer exists');
        return res.data.message;
      }
      return res.data.message;
    case 500:
      console.log('Auth0: customer creation error: ', res.data.message);
      throw new Error('Auth0: customer creation error. Message:' + res.data.message);
    default:
      console.log('Auth0: Unexpected response', res.data.message);
      throw new Error('Unexpected response: ' + res.data.message);
  }
};
